import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericApiResponse } from '../models/genericApiResponse';
import { HistoryDetailed, HistorySummary } from '../models/history';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  private API_BASE = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Get list of time tracking records based on filter parameters.
   * @param params date_from | date_to | user_id[] | client_id[] | project_id[] | tasktype_id[] | billable=1 or 0 | keyword | offset | limit | sort_by
   * @returns Time tracking records with additional meta data.
   */
  filterRecords(params: {}): Observable<HistoryDetailed> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `report/detailed`, {
      params: params
    })
      .pipe(
        map(res => {
          if(res.status == 'OK') {
            //successful add

            return res.data;

          } else {

            throw new Error(res.message);

          }
        })
      )

  }


  /**
   * Get aggregated time tracking data in different viewing modes for populating the column charts based on filter parameters. Pagination and sorting params don't have any effects here.
   * @param params date_from | date_to | user_id[] | client_id[] | project_id[] | tasktype_id[] | billable=1 or 0 | keyword
   * @returns Array of aggregated data about the filtered time tracking records in multiple viewing modes based on the provided date range.
   */
  getSummary(params: {}): Observable<HistorySummary[]> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `report/summary`, {
      params: params
    })
      .pipe(
        map(res => {
          if(res.status == 'OK') {
            //successful add

            return res.data;

          } else {

            throw new Error(res.message);

          }
        })
      )

  }

  generateHash(params: {}): Observable<string> {
    return this.http.post<GenericApiResponse>(this.API_BASE + `report/share`, params)
      .pipe(
        map((res) => {

          if(res.status == 'OK') {

            return res.data;

          } else {

            throw new Error(res.message);

          }
        })
      )
  }

}
